$dark: #212529;
$red: #f61a42;
$green: #77bd43;
$blue: #63c3d1;
$pink: #ea4aaa;

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
