@font-face {
  font-family: "Scene Pro Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Scene Pro Regular"), url("SceneProRg.woff") format("woff");
}

@font-face {
  font-family: "Scene Pro Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Scene Pro Bold"), url("SceneProBold.woff") format("woff");
}

@font-face {
  font-family: "Typewriter";
  font-style: normal;
  font-weight: normal;
  src: local("Typewriter"), url("Typewriter.woff") format("woff");
}
