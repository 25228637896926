.site-footer {
  text-align: center;
  background-color: #f4f4f4;
  color: #515151;
  padding: 40px 0;
}

.page-width {
  zoom: 1;
  max-width: 980px;
  padding: 0 20px;
  margin: 0 auto;
}

.flex-footer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  -o-align-items: flex-start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: auto;
}

.site-footer__bottom {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  a {
    text-decoration: underline !important;

    &:hover {
      opacity: 0.5;
    }
  }
}

.flex-footer__item {
  -webkit-flex: 1 1 100%;
  -moz-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  padding: 0 0 20px;
}

.page-width::after {
  content: "";
  display: table;
  clear: both;
}

.site-footer__linklist {
  list-style: none;
  padding: 0;
  margin: 0;
}

.site-footer__linklist-item {
  display: inline-block;
  padding: 0 10px;
  margin-bottom: 10px;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:hover {
    opacity: 0.5;
  }
}

.list--inline {
  padding: 0;
  margin: 0;
}

.list--inline li {
  display: inline-block;
  margin-bottom: 0;
}

.social-links__icon {
  margin-right: 10px;
}

.site-footer a {
  color: #515151;
}

.social-links a {
  margin-right: 20px;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.15s ease-out;
}

.social-links a:last-child {
  margin-right: 0;
}

.social-links .icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.social-links svg:not(:root) {
  overflow: hidden;
}

.social-links .icon {
  fill: #515151;
}

.icon__fallback-text,
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

.payment-icon {
  margin-right: 8px;
  line-height: 30px;
}

.list--inline li {
  display: inline-block;
  margin-bottom: 0;
}

.payment-icons .icon {
  width: 38px;
  height: 24px;
}

@media only screen and (max-width: 749px) {
  .site-footer__social-icons {
    text-align: center !important;
    padding-bottom: 30px;
  }

  .site-footer__payment-icons {
    padding-top: 30px;
  }
}

@media only screen and (min-width: 750px) {
  .site-footer__linklist {
    margin-top: 5px;
    text-align: left;
  }

  .payment-icons {
    text-align: right;
  }

  .flex-footer__item--one-third {
    flex: 0 0 33%;
  }

  .site-footer__social-icons {
    text-align: left;
  }

  .site-footer__linklist-item {
    display: block;
    padding: 0 20px 0 0;
  }

  .flex-footer__item {
    -webkit-flex: 1 1 25%;
    -moz-flex: 1 1 25%;
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    padding: 0 10px;
  }

  .site-footer__bottom {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .flex-footer {
    padding-bottom: 40px;
  }
}
/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
