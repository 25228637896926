// Global variables
@import "../../../utilities/variables";

.line-item {
  list-style: none;
  border-bottom: 1px #dedede solid;
  opacity: 1;
  animation-duration: 0.5s;
  transition: all 0.5s;

  &:last-child {
    border-bottom: none;
  }

  img {
    max-width: 100px;
    height: auto;
  }

  .preview {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 50%;
    border-radius: 50%;
    border: 3px $blue solid;
  }

  .btn {
    margin: 0;

    &.btn-outline-red {
      text-transform: initial;
      padding: 0.5rem 0.9rem;
    }

    &.btn-outline-blue {
      text-transform: initial;
      padding: 0.5rem 0.9rem;
    }
  }

  .flex-center {
    height: auto;
  }

  .col-auto {
    padding-right: 0.3rem;
  }

  .slider {
    top: -5px;
    z-index: 1;
  }

  &.move {
    .slider {
      display: none;
    }

    opacity: 0;
    transform: translateX(50px);
  }
}

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
