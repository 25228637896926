@import "../../../utilities/variables";

.cookie-modal {
  .modal-title {
    text-align: center;
    width: 100%;
  }

  .form-check-input[type="checkbox"].filled-in:checked + label:after,
  label.btn input[type="checkbox"].filled-in:checked + label:after {
    background-color: $blue;
    border: 2px solid $blue;
  }
  .form-check-input[type="checkbox"]:disabled:checked + label:after,
  label.btn input[type="checkbox"]:disabled:checked + label:after {
    background-color: lighten($blue, 35%);
    border: 2px solid lighten($blue, 35%);
  }
}

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
