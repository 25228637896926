// Global variables
@import "../../../utilities/variables";

#cart {
  margin-top: 4rem;

  .shipping {
    padding: 1rem;
    background: white;
    border-radius: 0.4rem;

    .text-muted {
      color: lighten(#6c757d, 20%) !important;
    }
  }

  .bg-success {
    background-color: $blue !important;
  }

  .bg-white {
    border-radius: 0.4rem;
  }

  .btn-success {
    background-color: $blue !important;

    &:hover {
      background-color: darken($blue, 5%) !important;
    }
  }

  svg {
    max-height: 200px;
    max-width: 100%;
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
