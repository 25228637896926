@import "./variables";
@import "./fonts";

body {
  font-family: "Scene Pro Regular", "Roboto", Arial, Tahoma, sans-serif !important;
  color: #515151;
}

.flyout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.navbar {
  box-shadow: none;

  .carticon {
    width: 50px;
    height: 50px;
    padding: 0;
    text-align: center;
    line-height: 50px;

    &:hover {
      svg {
        transform: scale(1.1);
      }
    }

    svg {
      fill: #515151;
      width: 26px;
      transition: all 0.2s ease;
    }
  }
}

// Custom colors
.bg-dark {
  background-color: $dark !important;

  &.darken-1 {
    background-color: darken($dark, 5%) !important;
  }

  &.darken-2 {
    background-color: darken($dark, 10%) !important;
  }

  &.darken-3 {
    background-color: darken($dark, 15%) !important;
  }

  &.lighten-1 {
    background-color: lighten($dark, 10%) !important;
  }

  &.lighten-2 {
    background-color: lighten($dark, 20%) !important;
  }

  &.lighten-3 {
    background-color: lighten($dark, 30%) !important;
  }
}

.line {
  height: 6px;
  width: 100px;
  background: $blue;
  display: block;
  margin: auto;
}

.edge-header {
  height: 200px;
}

.written {
  font-family: "Scene Pro Bold", Arial, Tahoma, sans-serif !important;
  position: relative;
}

.typewriter {
  font-family: "Typewriter" !important;
}

.bg-red {
  background-color: $red !important;

  &.darken-1 {
    background-color: darken($red, 10%) !important;
  }

  &.darken-2 {
    background-color: darken($red, 20%) !important;
  }

  &.darken-3 {
    background-color: darken($red, 30%) !important;
  }

  &.lighten-1 {
    background-color: lighten($red, 10%) !important;
  }

  &.lighten-2 {
    background-color: lighten($red, 20%) !important;
  }

  &.lighten-3 {
    background-color: lighten($red, 30%) !important;
  }
}

.bg-pattern {
  background: url("../assets/bg.png");
  //border-bottom: 1px lightgrey solid;
}

.bg-green {
  background-color: $green !important;

  &.darken-1 {
    background-color: darken($green, 10%) !important;
  }

  &.darken-2 {
    background-color: darken($green, 20%) !important;
  }

  &.darken-3 {
    background-color: darken($green, 30%) !important;
  }

  &.lighten-1 {
    background-color: lighten($green, 10%) !important;
  }

  &.lighten-2 {
    background-color: lighten($green, 20%) !important;
  }

  &.lighten-3 {
    background-color: lighten($green, 30%) !important;
  }
}

// Custom text
.red-text {
  color: $red !important;
}

.pink-text {
  color: $pink !important;
}

.text-blue {
  color: $blue !important;
}

.text-grey {
  color: #515151;
}

// Buttons
.btn {
  box-shadow: none !important;
  text-transform: initial;

  i {
    margin-right: 0.3rem;
  }

  &.btn-blue {
    background: $blue !important;

    &:hover {
      background: darken($blue, 5%) !important;
    }
  }

  &.btn-outline-red {
    border: 2px solid #d8d9e1 !important;
    background: transparentize($color: #ff3547, $amount: 0.99) !important;
    color: #ff3547 !important;

    &:hover {
      border: 2px solid #ff3547 !important;
      background-color: transparentize(
        $color: #ff3547,
        $amount: 0.95
      ) !important;
    }

    &.active {
      box-shadow: none;
      border: 2px solid #ff3547 !important;
      background-color: transparentize(
        $color: #ff3547,
        $amount: 0.95
      ) !important;
    }
  }

  &.btn-outline-blue {
    color: $blue !important;
    border: 2px solid $blue !important;

    &:hover {
      background-color: transparentize($color: $blue, $amount: 0.95) !important;
    }

    &.active {
      box-shadow: none;
      border: 2px solid $blue !important;
      background-color: transparentize($color: $blue, $amount: 0.95) !important;
    }
  }

  &.btn-grey {
    background: lightgrey !important;

    &:hover {
      background: darken(lightgrey, 5%) !important;
    }
  }

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }
}

// Cards
.card {
  box-shadow: none;
}

// Generic
.clickable {
  cursor: pointer !important;
}

// Fix icon spacing in buttons
.btn {
  i {
    padding-right: 0.2rem;
  }
}

// Dropzone
.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-align: center;
  border-width: 4px;
  border-radius: 0.4rem;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: all 0.24s ease-in-out;
  background-size: cover;
  background-position: center center;

  p {
    margin-bottom: 0;
  }

  &.active {
    border-color: $blue;
  }

  &.accept {
    border-color: #00e676;
  }

  &.reject {
    border-color: #ff1744;
  }

  &:hover {
    background-color: lighten(#fafafa, 4%);
  }
}

.thumbs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;

  .thumb {
    display: inline-flex;
    border-radius: 4px;
    border: 1px solid #eaeaea;
    margin-bottom: 8;
    margin-right: 8;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;

    .thumb-inner {
      display: flex;
      min-width: 0;
      overflow: hidden;

      .view {
        cursor: pointer;

        .mask {
          transition: all 0.2s ease;
        }

        &:hover {
          .mask {
            background: rgba(255, 0, 0, 0.2) !important;
          }
        }
      }

      img {
        display: block;
        width: auto;
        height: 100%;
      }
    }
  }
}

.range-field {
  .thumb {
    display: none;
  }
}

// YEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE

.free-bird {
  margin-top: -150px;
}

#config {
  .puzzle-preview {
    max-width: 50%;
    margin: auto;

    .col {
      padding: 0;
      border: 1px lightgrey solid;
      border-radius: 0;

      .view {
        height: 100%;
      }

      img {
        width: 100%;
        border-radius: 0 !important;
        height: 100%;
        width: 100%;
      }
    }
  }

  .puzzle {
    .custom-progress {
      .custom-progress-item {
        width: 9%;
      }
    }

    .preview {
      .col-lg-3 {
        background: red;
        padding: 0;
        margin-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 0.1rem;

        .view {
          border: 2px lightgrey solid;
          border-radius: 0.25rem;

          img {
            position: relative;
          }
        }

        img {
          border-radius: 0.25rem;
          width: 100%;
        }

        .preview-text {
          text-align: center;
          font-family: "Typewriter" !important;
          vertical-align: center;
          overflow: hidden;

          .text-container {
            padding-left: 2rem;
            padding-right: 2rem;
            line-height: 0.8rem;
          }

          .mask {
            white-space: pre-line;
          }

          span {
            font-size: 70%;
          }
        }
      }
    }
  }

  .example {
    overflow: hidden;

    .img-fluid {
      width: 100%;
    }

    img {
      border: 2px lightgrey solid;
      border-radius: 0.5rem;
    }

    .cropper-hopper {
      img {
        border: none;
        border-radius: 0;
      }
    }

    textarea {
      resize: none;
      max-height: 70%;
      text-align: center;
      border: 2px lightgrey dashed !important;
      border-radius: 0.5rem !important;
      font-family: "Typewriter" !important;
      vertical-align: center;
      font-size: 120%;
      overflow: hidden;
      outline: none !important;
      width: 70%;
      line-height: 1.4rem;
      padding: 2%;

      &:focus {
        border: 2px darken(lightgrey, 5%) dashed !important;
      }
    }
  }

  .half-selector {
    .wrapper {
      background-image: url("../assets/placeholder.jpg") !important;
      background-size: cover;

      &:hover {
        border-color: darken(#eeeeee, 10%);
        color: darken(#eeeeee, 40%);
      }
    }

    .img-fluid {
      width: 100%;
    }

    .mask {
      flex-direction: column;

      .image {
        width: 100%;
        height: 72%;

        .cropper-hopper {
          border-radius: 0 !important;
          padding-bottom: 72%;
        }
      }

      .text {
        //padding-bottom: 8%;
        width: 85%;
        height: 28%;

        textarea {
          font-size: 100%;
          line-height: 1.2rem;
        }
      }
    }
  }

  .puzzle-preview-front {
    .col-lg-3 {
      padding: 0;
      margin-bottom: 1rem;

      img {
        width: 100%;
      }

      .background-image {
        width: 100%;

        .mask {
          border-radius: 0;
          border: none !important;
        }

        &.mask {
          flex-direction: column;
          border: none !important;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;

          .image {
            height: 72%;
            width: 100%;
          }

          .text {
            height: 28%;
            width: 80%;
            line-height: 40%;
          }
        }

        .view {
          border-radius: 0;
          border: none !important;
        }
      }

      .view {
        border: 2px lightgrey solid;
        border-radius: 0.25rem;

        img {
          border: none !important;
          position: relative;
        }

        .mask {
          border: 0.4rem solid white;
        }
      }

      .preview-text {
        text-align: center;
        font-family: "Typewriter" !important;
        vertical-align: center;
        overflow: hidden;
        line-height: 60%;

        .text-container {
          padding-left: 2rem;
          padding-right: 2rem;
        }

        .mask {
          white-space: pre-line;
        }

        span {
          font-size: 50%;
        }
      }
    }
  }

  .preview {
    .col-lg-3 {
      padding: 0;
      margin-bottom: 1rem;

      .watermark {
        position: absolute;
        bottom: 1;
        right: 1;
        width: 21%;
      }

      &:nth-child(even) {
        padding-right: 1rem;
        padding-left: 0.1rem;

        img {
          border: 2px lightgrey solid;
        }
      }
      &:nth-child(odd) {
        padding-left: 1rem;
        padding-right: 0.1rem;

        .view {
          border: 2px lightgrey solid;
          border-radius: 0.25rem;

          img {
            position: relative;
            border-radius: 0;
          }
        }
      }

      img {
        border-radius: 0.25rem;
        width: 100%;
      }

      .preview-text {
        text-align: center;
        font-family: "Typewriter" !important;
        vertical-align: center;
        overflow: hidden;

        .text-container {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          line-height: 80%;
        }

        .mask {
          white-space: pre-line;
        }

        span {
          font-size: 70%;
        }
      }
    }
  }

  .custom-progress {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .custom-progress-item {
      cursor: pointer;
      background: #eee;
      width: 10%;
      padding: 0.6rem 0;
      border-radius: 0.5rem;
      transition: all 0.2s ease;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: lighten(#eee, 4%);
      }

      &.active {
        background: $blue;
        color: white;

        &:hover {
          background: lighten($blue, 10%);
        }
      }

      &.success {
        background: #88df68;
        color: white;

        &:hover {
          background: lighten(#88df68, 5%);
        }
      }

      &.danger {
        background: #fe7968;
        color: white;

        &:hover {
          background: lighten(#fe7968, 5%);
        }
      }
    }
  }
}

.selector-wrapper {
  padding-top: 100%;
  position: relative;

  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-image: url("../assets/placeholder.jpg") !important;
    background-size: cover;

    &:hover {
      border-color: darken(#eeeeee, 10%);
      color: darken(#eeeeee, 40%);
    }
  }

  .cropper-hopper-container {
    top: 0;
    left: 0;
    right: 0;
  }
}

#bilderwelt {
  .preview {
    .col-lg-3 {
      .view {
        border: 2px lightgrey solid;
        border-radius: 0.25rem;
      }

      img {
        border: none !important;
        border-radius: 0 !important;
      }
    }
  }
}

.time-info {
  color: #ebb217;
  background-color: white;
}

.watermark {
  position: absolute;
  bottom: 2.5%;
  right: 2.5%;
  background: white;
  padding: 0.2rem !important;

  img {
    max-width: 100%;
    width: 80px;
  }
}

@media only screen and (max-width: 1400px) {
  #config .example textarea {
    font-size: 110%;
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    line-height: 60% !important;

    span {
      font-size: 50% !important;
    }
  }

  .text-container {
    font-size: 100% !important;
  }
}

@media only screen and (max-width: 1200px) {
  #config .example textarea {
    font-size: 100%;
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    .text {
      line-height: 30% !important;
    }

    span {
      font-size: 40% !important;
    }
  }

  .half-selector {
    textarea {
      font-size: 80% !important;
      line-height: 110% !important;
    }
  }

  .text-container {
    font-size: 80% !important;
  }
}

@media only screen and (max-width: 1000px) {
  #config .example textarea {
    font-size: 140%;
  }

  .text-container {
    font-size: 280%;
  }

  .half-selector {
    textarea {
      font-size: 125% !important;
      line-height: 110% !important;
    }
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    .text {
      line-height: 120% !important;
    }

    span {
      font-size: 130% !important;
    }
  }
}

@media only screen and (max-width: 900px) {
  #config .example textarea {
    font-size: 135%;
  }

  .text-container {
    font-size: 270%;
  }

  .half-selector {
    textarea {
      font-size: 125% !important;
      line-height: 110% !important;
    }
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    line-height: 135% !important;

    span {
      font-size: 125% !important;
    }
  }
}

@media only screen and (max-width: 800px) {
  #config .example textarea {
    font-size: 150%;
  }

  .text-container {
    font-size: 310% !important;
  }

  .half-selector {
    textarea {
      font-size: 125% !important;
      line-height: 110% !important;
    }
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    line-height: 135% !important;

    span {
      font-size: 115% !important;
    }
  }
}

@media only screen and (max-width: 700px) {
  #config .example textarea {
    font-size: 115%;
  }

  .puzzle-preview {
    max-width: 100;
  }

  .preview {
    .col-lg-3 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .text-container {
    font-size: 260%;
  }

  .half-selector {
    textarea {
      font-size: 110% !important;
      line-height: 110% !important;
    }
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    line-height: 125% !important;

    span {
      font-size: 115% !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  #config .example textarea {
    font-size: 100%;
  }

  .puzzle-preview {
    max-width: 95%;
  }

  .preview {
    .col-lg-3 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .text-container {
    font-size: 260%;
  }

  .half-selector {
    textarea {
      font-size: 110% !important;
      line-height: 110% !important;
    }
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    line-height: 125% !important;

    span {
      font-size: 115% !important;
    }
  }
}

@media only screen and (max-width: 550px) {
  #config .example textarea {
    font-size: 135%;
  }

  .text-container {
    font-size: 250% !important;
  }

  .half-selector {
    textarea {
      font-size: 110% !important;
      line-height: 110% !important;
    }
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    line-height: 120% !important;

    span {
      font-size: 110% !important;
    }
  }
}

@media only screen and (max-width: 500px) {
  #config .example textarea {
    font-size: 100%;
    line-height: 110%;
  }

  .text-container {
    font-size: 220%;
  }

  .half-selector {
    textarea {
      font-size: 95% !important;
      line-height: 1.1rem !important;
    }
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    line-height: 110% !important;

    span {
      font-size: 100% !important;
    }
  }
}

@media only screen and (max-width: 450px) {
  #config .example textarea {
    font-size: 100%;
  }

  .text-container {
    font-size: 205% !important;
  }

  .half-selector {
    textarea {
      font-size: 80% !important;
      line-height: 1rem !important;
    }
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    .text {
      line-height: 100% !important;
    }

    span {
      font-size: 85% !important;
    }
  }
}

@media only screen and (max-width: 400px) {
  #config .example textarea {
    font-size: 90%;
  }

  .text-container {
    font-size: 180% !important;
  }

  .half-selector {
    textarea {
      font-size: 75% !important;
      line-height: 120% !important;
    }
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    line-height: 90% !important;

    span {
      font-size: 80% !important;
    }
  }
}

@media only screen and (max-width: 375px) {
  #config .example textarea {
    font-size: 90%;
  }

  .text-container {
    font-size: 185% !important;
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    line-height: 85% !important;

    span {
      font-size: 75% !important;
    }
  }
}

@media only screen and (max-width: 350px) {
  #config .example textarea {
    font-size: 75%;
  }

  .text-container {
    font-size: 155% !important;
  }

  .half-selector {
    textarea {
      font-size: 65% !important;
      line-height: 120% !important;
    }
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    line-height: 80% !important;

    span {
      font-size: 70% !important;
    }
  }
}

@media only screen and (max-width: 300px) {
  #config .example textarea {
    font-size: 70%;
  }

  .text-container {
    font-size: 140%;
  }

  .half-selector {
    textarea {
      font-size: 50% !important;
      line-height: 120% !important;
    }
  }

  #config .puzzle-preview-front .col-lg-3 .preview-text {
    line-height: 70% !important;

    span {
      font-size: 60% !important;
    }
  }
}

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
