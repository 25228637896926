@import "../../../utilities/variables";

body {
  background: #fff;
}

.product-item {
  .product-item-container {
    background: #f4f4f4;
    margin: 1rem;

    img {
      transition: all 0.2s ease;
    }
  }

  &:hover {
    .product-item-container {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.carousel-multi-item {
  width: 100%;

  .controls-top {
    margin: 0;

    .btn-floating {
      box-shadow: none;
      border-radius: 0.2rem;
      width: 100px;
      background: $blue !important;

      &:hover {
        background: darken($blue, 5%) !important;
      }
    }
  }
}

.config {
  .wrapper {
    height: 100%;
    display: flex;
    justify-content: center;

    p {
      margin: 0;
    }
  }
}

.cropper-hopper {
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  padding-bottom: 100%;
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
